import axios from "axios";
import {
    getUrl, getUrlEmbed, REGISTER_BY_EMAIL, VERIFY_REGISTRATION_OTP, VALIDATE_TOKEN, SUBMIT_EVENT_SOCIAL_REGISTRATION_FORM,
    UPDATE_USER_PROFILE_DETAIL, GET_USER_PROFILE_DETAIL, GET_NETWORKING_INTEREST, GET_USER_TIMEZONE_LIST, VERIFY_SPEAKER_TOKEN, EMBEDDED_REGISTRATION_BY_EMAIL, VERIFY_EMBED_REGISTRATION_OTP, VALIDATE_EMBED_REGISTRATION_TOKEN, CHECK_BOOTH_OWNER_URL
} from "../../constants";
import { showToast } from "../../utils";
import { actionTypes } from "../actionTypes";

/**
 * for all users which come to the event page
 * @param {*} eventId 
 * @param {*} email 
 * @returns 
 */
export const submitRegistrationEmail = (eventId, email) => async (dispatch) => {
    dispatch({
        type: actionTypes.REGISTRATION_INITIATED
    });

    try {
        const res = await axios.post(getUrl(REGISTER_BY_EMAIL, eventId), { email });
        dispatch({
            type: actionTypes.REGISTRATION_SUCCESS,
            code: res.data.code,
            payload: res.data.data,
            message: res.data.message
        });
        // showToast({ autoClose: 3000, message: <span dangerouslySetInnerHTML={{ __html: res.data.message }}></span>, variant: 'success' });
    } catch (error) {
        dispatch({
            type: actionTypes.REGISTRATION_ERROR
        });
        showToast({ autoClose: 3000, variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * send otp for varification
 */
export const submitRegistrationOtp = (eventId, email, otp) => async (dispatch) => {
    dispatch({
        type: actionTypes.OTP_SUBMIT_INITIATED
    });

    try {
        const res = await axios.post(getUrl(VERIFY_REGISTRATION_OTP, eventId), { email, token: otp });
        dispatch({
            type: actionTypes.RESET_EVENT_REGISTRATION_STATUS
        });
        // showToast({ message: res.data.message, variant: 'success' });

        dispatch({
            type: actionTypes.OTP_SUBMIT_SUCCESS,
            payload: res.data.data
        });
    } catch (error) {
        dispatch({
            type: actionTypes.OTP_SUBMIT_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

// function to verify speaker invitation token
export const eventSpeakerTokenVerify = (eventId, tokenData) => async (dispatch) => {
    dispatch({
        type: actionTypes.VERIFY_SPEAKER_TOKEN_INITIATED
    });
    try {
        const res = await axios.post(getUrl(VERIFY_SPEAKER_TOKEN, eventId), tokenData);

        // dispatch({
        //     type: actionTypes.ON_VERIFY_SPEAKER_TOKEN_SUCCESS,
        //     payload: res.data.data
        // });

        dispatch({
            type: actionTypes.VERIFY_SPEAKER_TOKEN_SUCCESS,
            payload: res.data.data
        });

        showToast({ variant: 'success', message: res.data.message });

        return res.data;
    } catch (error) {
        dispatch({
            type: actionTypes.VERIFY_SPEAKER_TOKEN_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || "some error occurred" });
    }
}

/**
 * validate user token
 */
export const validateToken = (eventId, callback) => async (dispatch) => {
    try {
        dispatch({
            type: actionTypes.TOKEN_VALIDATION_INITIATED
        })
        const res = await axios.get(getUrl(VALIDATE_TOKEN, eventId));
        dispatch({
            type: actionTypes.TOKEN_VALIDATION_SUCCESS,
            payload: res.data.data
        })
        if (res.data?.data?.registration_complete === 1) {
            dispatch({
                type: actionTypes.COMPLETE_REGISTRATION,
            })
        } else {
            dispatch({
                type: actionTypes.RESET_EVENT_REGISTRATION_STATUS,
            })
        }
        // if(res.data?.data?.profile_complete === 1)
        // {
        //     dispatch({
        //         type: actionTypes.SET_LOBBY_USER_PROFILE_SECTION_STATUS,
        //     })
        // }

        dispatch({
            type: actionTypes.EVENT_ENTRY_TYPE_FETCHED,
            payload: res.data.data.entry_type
        })

        if (callback) {
            callback();
        }
    } catch (error) {
        dispatch({
            type: actionTypes.TOKEN_VALIDATION_ERROR
        })

        if (callback) {
            callback();
        }

    }
}

/**
 * Social Login
 */
export const socialLogin = (token, driver, eventId) => async (dispatch) => {
    dispatch({
        type: actionTypes.SOCIAL_REGISTRATION_INITIATED
    });

    try {
        const res = await axios.post(getUrl(SUBMIT_EVENT_SOCIAL_REGISTRATION_FORM, eventId), { token: token, driver: driver });

        dispatch({
            type: actionTypes.RESET_EVENT_REGISTRATION_STATUS
        });

        // console.log(res.data.message);
        // showToast({ message: res.data.message, variant: 'success' });

        dispatch({
            type: actionTypes.SOCIAL_REGISTRATION_SUCCESS,
            payload: res.data.data
        });

    } catch (error) {
        dispatch({
            type: actionTypes.SOCIAL_REGISTRATION_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * logout
 */
export const logout = () => (dispatch) => {
    dispatch({
        type: actionTypes.LOGOUT
    })
}

export const onUserProfileSave = (eventId, values) => async (dispatch) => {
    dispatch({
        type: actionTypes.USER_PROFILE_INITIATED
    });
    try {
        const res = await axios.post(getUrl(UPDATE_USER_PROFILE_DETAIL, eventId), values);
        dispatch({
            type: actionTypes.USER_PROFILE_SUCCESS,
            payload: res.data
        });
        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.USER_PROFILE_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getUserDetails = (eventId) => async (dispatch) => {
    try {
        const res = await axios.get(getUrl(GET_USER_PROFILE_DETAIL, eventId));
        dispatch({
            type: actionTypes.GET_USER_DETAILS,
            payload: res.data.data
        });
    } catch (error) {
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const checkBoothOwner = (eventId) => async (dispatch) => {

    dispatch({
        type: actionTypes.CHECK_BOOTH_OWNER_INITIATED
    });

    try {
        const res = await axios.get(getUrl(CHECK_BOOTH_OWNER_URL, eventId));
        dispatch({
            type: actionTypes.CHECK_BOOTH_OWNER_SUCCESS,
            payload: res.data.data
        });
    } catch (error) {
        dispatch({
            type: actionTypes.CHECK_BOOTH_OWNER_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getUserInterestsDetails = (eventId) => async (dispatch) => {
    try {
        const res = await axios.get(getUrl(GET_NETWORKING_INTEREST, eventId));
        dispatch({
            type: actionTypes.GET_USER_INTERESTS,
            payload: res.data.data
        });
    } catch (error) {
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getUserTimeZoneList = (eventId) => async (dispatch) => {
    try {
        const res = await axios.post(getUrl(GET_USER_TIMEZONE_LIST, eventId));
        dispatch({
            type: actionTypes.GET_USER_TIMEZONE_LIST,
            payload: res.data.data
        });
    } catch (error) {
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getLanguageList = (payload) => async (dispatch) => {
    dispatch({
        type: actionTypes.GET_LANG_LIST,
        payload
    });
}

export const setLangRegion = (payload) => async (dispatch) => {
    dispatch({
        type: actionTypes.SET_LANG_REGION,
        payload
    });
}


//Embed Registration APIs

/**
 * for all users which come to the event page
 * @param {*} eventId 
 * @param {*} email 
 * @returns 
 */
export const submitEmbedRegistrationEmail = (eventId, email) => async (dispatch) => {
    dispatch({
        type: actionTypes.EMBED_REGISTRATION_INITIATED
    });

    try {
        const res = await axios.post(getUrlEmbed(EMBEDDED_REGISTRATION_BY_EMAIL, eventId), { email });
        dispatch({
            type: actionTypes.EMBED_REGISTRATION_SUCCESS,
            code: res.data.code,
            payload: res.data.data,
            message: res.data.message
        });
        // showToast({ autoClose: 3000, message: <span dangerouslySetInnerHTML={{ __html: res.data.message }}></span>, variant: 'success' });
    } catch (error) {
        dispatch({
            type: actionTypes.EMBED_REGISTRATION_ERROR
        });
        showToast({ autoClose: 3000, variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * send otp for varification
 */
export const submitEmbedRegistrationOtp = (eventId, email, otp) => async (dispatch) => {
    dispatch({
        type: actionTypes.EMBED_OTP_SUBMIT_INITIATED
    });

    try {
        const res = await axios.post(getUrlEmbed(VERIFY_EMBED_REGISTRATION_OTP, eventId), { email, token: otp });
        dispatch({
            type: actionTypes.RESET_EMBED_REGISTRATION_STATUS
        });
        // showToast({ message: res.data.message, variant: 'success' });

        dispatch({
            type: actionTypes.EMBED_OTP_SUBMIT_SUCCESS,
            payload: res.data.data
        });
    } catch (error) {
        dispatch({
            type: actionTypes.EMBED_OTP_SUBMIT_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * validate user token
 */
export const validateTokenEmbedRegistration = (eventId, callback) => async (dispatch) => {
    try {
        dispatch({
            type: actionTypes.EMBED_TOKEN_VALIDATION_INITIATED
        })
        const res = await axios.get(getUrlEmbed(VALIDATE_EMBED_REGISTRATION_TOKEN, eventId));
        dispatch({
            type: actionTypes.EMBED_TOKEN_VALIDATION_SUCCESS,
            payload: res.data.data
        })
        if (res.data?.data?.registration_complete === 1) {
            dispatch({
                type: actionTypes.COMPLETE_EMBED_REGISTRATION,
            })
        } else {
            dispatch({
                type: actionTypes.RESET_EMBED_REGISTRATION_STATUS,
            })
        }
        // if(res.data?.data?.profile_complete === 1)
        // {
        //     dispatch({
        //         type: actionTypes.SET_LOBBY_USER_PROFILE_SECTION_STATUS,
        //     })
        // }

        // dispatch({
        //     type: actionTypes.EVENT_ENTRY_TYPE_FETCHED,
        //     payload: res.data.data.entry_type
        // })

        if (callback) {
            callback();
        }
    } catch (error) {
        dispatch({
            type: actionTypes.EMBED_TOKEN_VALIDATION_ERROR
        })

        if (callback) {
            callback();
        }

    }
}
