export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_BASE_URL_EMBED = process.env.REACT_APP_EMBED_API_URL;


export const getUrl = (url, eid, uid, sid) => {
  if (!eid && !uid && !sid) {
    return API_BASE_URL + url;
  }
  else if (!uid && !sid) {
    return API_BASE_URL + url.replace('E_ID', eid);
  }
  else if (!sid) {
    return API_BASE_URL + url.replace('E_ID', eid).replace('U_ID', uid);
  }
  else {
    return API_BASE_URL + url.replace('E_ID', eid).replace('U_ID', uid).replace('S_ID', sid);
  }
}

export const getUrlEmbed = (url, eid, uid, sid) => {
  if (!eid && !uid && !sid) {
    return API_BASE_URL_EMBED + url;
  }
  else if (!uid && !sid) {
    return API_BASE_URL_EMBED + url.replace('E_ID', eid);
  }
  else if (!sid) {
    return API_BASE_URL_EMBED + url.replace('E_ID', eid).replace('U_ID', uid);
  }
  else {
    return API_BASE_URL_EMBED + url.replace('E_ID', eid).replace('U_ID', uid).replace('S_ID', sid);
  }
}

export const GET_DIAL_CODE_URL = 'E_ID/countryCode';
export const GET_EVENT_DETAILS = `E_ID/get`;
export const GET_EXPIRED_EVENT_DETAILS = `E_ID/getExpireEvent`;
export const GET_EVENT_REGISTRATION_DETAILS = `E_ID/registration/details`; // primarily to get event registration form fields
export const REGISTER_BY_EMAIL = `E_ID/auth/check`;
export const VERIFY_REGISTRATION_OTP = `E_ID/auth/login`;
export const VALIDATE_TOKEN = `E_ID/auth/token`;
export const SUBMIT_EVENT_REGISTRATION_FORM = 'E_ID/registration/attendee';
export const SUBMIT_EVENT_SOCIAL_REGISTRATION_FORM = 'E_ID/auth/login/social';
export const GET_LOBBY_DETAILS = 'E_ID/lobby';
export const GET_STAGE_DETAILS = 'E_ID/stage';
export const GET_PEOPLE_LIST = 'E_ID/people/list';
export const GET_SPEAKERS_LIST = 'E_ID/speaker/list';
export const GET_ENTER_STAGE_DETAILS = 'E_ID/stage/U_ID/S_ID/get';
export const GET_STREAMING_DATA = 'E_ID/stage/U_ID/S_ID/getStreamingData';
export const GET_ARCHIVED_STAGE_DETAILS = 'E_ID/session/U_ID/S_ID/get';
export const GET_RECORDING_STAGE_DETAILS = 'E_ID/stage/U_ID/S_ID/getrecordingstage';
export const GET_AGORA_AUTH_TOKEN = `E_ID/stage/get/token`;
export const GET_AGORA_AUTH_RECORDING_TOKEN = `E_ID/stage/get/recording/token`;
export const GET_STAGE_RECORDING_STATUS = `E_ID/stage/U_ID/S_ID/getRecordingStatus`;
export const GET_ROOM_DETAILS = 'E_ID/room';
export const GET_ROOM_LINK = 'E_ID/room/whereby';
export const GET_NETWORKING_INTEREST = 'E_ID/networking/interest';
export const GET_USER_PROFILE_DETAIL = 'E_ID/auth/userProfile';
export const UPDATE_USER_PROFILE_DETAIL = 'E_ID/auth/updateUserProfile';
export const UPDATE_EXPO_DETAIL = 'E_ID/booth/U_ID/update';
export const GET_EXPO_ALL_LIST = 'E_ID/booth/list';
export const GET_EXPO_LIST_BY_ZONE = 'E_ID/zone/U_ID/get';
export const GET_EXPO_DETAIL = 'E_ID/booth/U_ID/get';
export const GET_SCHEDULE_DETAILS = 'E_ID/schedule';
export const GET_STATE_LIST = 'E_ID/registration/states';
export const GET_CITY_LIST = 'E_ID/registration/cities';
export const UPLOAD_FILE_URL = '/upload/uploadFile';
export const UPDATE_STAGE_BROADCASTING_STATUS_URL = 'E_ID/stage/U_ID/broadcast';
export const GET_STREAM_PLAYBACK_ID = 'E_ID/stage/U_ID/getStreamPlayback';
export const GET_SERVER_UTC_TIME = 'getUtc';
export const GET_ATTENDEE_RECENT_CHAT = 'E_ID/chat/recent';
export const GET_USER_TIMEZONE_LIST = 'E_ID/timeZone';



export const DEFAULT_SKINS_URL = 'E_ID/skin';

export const GET_ZONES_STATUS = 'E_ID/event/zones';
export const GET_ZONES_INTERNAL_SECTIONS_LIST = 'E_ID/event/zones/internal';
export const UPDATE_ZONE_STATUS = 'E_ID/event/zones/update';

export const GET_WIDGETS = 'E_ID/widget/U_ID/list'; // E_ID : event id, U_ID: layout id;
export const CREATE_WIDGET = 'E_ID/widget/U_ID/create'; // E_ID : event id, U_ID: layout id;
export const UPDATE_WIDGET = 'E_ID/widget/U_ID/update'; // E_ID : event id, U_ID: layout id;
export const DELETE_WIDGET = 'E_ID/widget/U_ID/delete'; // E_ID : event id, U_ID: layout id;

export const USER_TIME_SPENT_ROOM_JOIN = 'E_ID/room/U_ID/join';
export const USER_TIME_SPENT_ROOM_LEAVE = 'E_ID/room/leave';
export const SEND_ROOM_NOTIFICATION = 'E_ID/room/sendInvite';
export const EMOJI = 'E_ID/emoji';
export const NOTIFICATION = 'E_ID/notification/list';
export const NOTIFICATION_SEEN = 'E_ID/notification/seen';
export const INDIVIDUAL_NOTIFICATION_SEEN = 'E_ID/notification/read/U_ID';

export const GET_SCHEDULE_LIST_FOR_THEME = "E_ID/theme/schedule";
export const GET_BOOTH_LIST_FOR_THEME = "E_ID/theme/booths";


export const FEEDBACK_QUESTION_LIST = 'E_ID/feedback';
export const FEEDBACK_QUESTION_SUBMIT = 'E_ID/feedback/U_ID/userResponse';

export const GET_SESSION_SPEAKER_DETAIL = 'E_ID/sessionspeaker/U_ID/get';
export const UPDATE_SESSION_SPEAKER_DETAIL = 'E_ID/sessionspeaker/updateSessionSpeaker';
export const GET_UPDATED_SESSION_SPEAKERS = 'E_ID/sessionspeaker/U_ID/S_ID/getUpdatedSessionSpeakers';

export const DELETE_SESSION_SPEAKER = 'E_ID/sessionspeaker/U_ID/deleteSpeaker';

export const UPDATE_STAGE_BACKGROUND_IMAGE = 'E_ID/stage/U_ID/updateBackgroundImage';
export const TAGS = 'E_ID/tag/list';
export const VERIFY_SPEAKER_TOKEN = 'E_ID/registration/verifySpeakerToken';
export const UPLOAD_PDF = 'E_ID/upload/uploadPdfPreview';
export const DELETE_UPLOADED_PDF = 'E_ID/upload/deletePdfPreview';
export const NOTIFICATION_SOUND = 'E_ID/notification/soundStatus';

export const EMBEDDED_REGISTRATION_BY_EMAIL = `E_ID/auth/check`;
export const VERIFY_EMBED_REGISTRATION_OTP = `E_ID/auth/login`;
export const VALIDATE_EMBED_REGISTRATION_TOKEN = `E_ID/auth/token`;
export const EMBEDDED_EVENT_REGISTRATION_DETAILS = `E_ID/embedRegistration/details`;
export const SUBMIT_EMBEDDED_REGISTRATION_FORM = `E_ID/embedRegistration/attendee`;

export const BOOTH_FILE_ACCESS_URL = 'E_ID/booth/U_ID/boothFileAccess';
export const CHECK_BOOTH_OWNER_URL = 'E_ID/checkBoothOwner';

export const LEAVE_BOOTH_VIDEO_CALL_DATA_URL = 'E_ID/booth/U_ID/leaveBoothVideoCallData';
export const JOIN_BOOTH_VIDEO_CALL_DATA_URL = 'E_ID/booth/U_ID/joinBoothVideoCallData';

export const GET_MEETING_SCHEDULE_AVAILABILITY_URL = 'E_ID/me/availability';
export const UPDATE_MY_AVAILABILITY_URL = 'E_ID/me/availability/update';
export const GET_MEETING_SLOTS_URL = 'E_ID/meeting/slots';
export const BOOK_SELECTED_SLOTS_URL = 'E_ID/meeting/book';
export const MY_MEETINGS = 'E_ID/me/meetings';
export const UPDATE_MEETING_ACTION_URL = 'E_ID/me/meeting/action';
export const INITIATE_MEETING_URL = "E_ID/meeting/init";
export const JOIN_MEETING_URL = "E_ID/meeting/join";
export const LEAVE_MEETING_URL = "E_ID/meeting/leave";
